"use client";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileTabMenuView from "./ProfileTabMenuView";
import { TWELVE_COUNT } from "@/src/const/primitive";
import {
  PortfolioProfileData,
  PortfolioWebsites,
  isLoadingWebsite,
  isPortfolioWebsitesEndPage,
  userPortfolioPopularPost,
  userPortfolioTotalLikes,
} from "@/src/redux/reducers/slices";
import { userGetPortfolioDetail } from "@/src/redux/actions";
import { isEmpty } from "@/src/utils";

const ProfileTabMenuContainer = ({
  children,
  userNameParams,
  token,
  userPortfolioProfileData,
}) => {
  const [portfolioName, setPortfolioName] = useState("work");
  const { portfolioWebsitesData } = useSelector((state) => state.userPortfolio);
  const dispatch = useDispatch();

  const handleChangePortfolioName = (name) => {
    setPortfolioName(name);
  };

  const getUserDataPortfolioWebsite = async (payload) => {
    await dispatch(isLoadingWebsite(true));
    const response = await dispatch(
      userGetPortfolioDetail(userNameParams, payload)
    );
    await dispatch(PortfolioWebsites(response?.allWebsites));
    await dispatch(isPortfolioWebsitesEndPage(response?.isEnd));
    await dispatch(isLoadingWebsite(false));
  };

  useEffect(() => {
    const payload = {
      page: 1,
      limit: TWELVE_COUNT,
      orderBy: "ASC",
    };
    getUserDataPortfolioWebsite(payload);
  }, []);

  const handleFilterPortfolioWebsiteData = async (title, filterData) => {
    const payload = {
      page: 1,
      limit: TWELVE_COUNT,
      orderBy: isEmpty(filterData) ? "ASC" : filterData,
    };
    await getUserDataPortfolioWebsite(payload);
  };

  useEffect(() => {
    dispatch(PortfolioProfileData(userPortfolioProfileData?.profileData));
    dispatch(userPortfolioPopularPost(userPortfolioProfileData?.popularPost));
    dispatch(userPortfolioTotalLikes(userPortfolioProfileData?.totalLikes));
  }, [userPortfolioProfileData]);

  return (
    <ProfileTabMenuView
      {...{
        children,
        portfolioWebsitesData,
        userNameParams,
        token,
        portfolioName,
        handleChangePortfolioName,
        handleFilterPortfolioWebsiteData,
      }}
    />
  );
};

export default ProfileTabMenuContainer;

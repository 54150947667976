import { LoadMoreComponent } from "@/src/components/CommonComponents";
import { userGetPortfolioDetail } from "@/src/redux/actions";
import { Row } from "antd";
import React from "react";

const PortfolioWorkView = ({
  portfolioWebsitesData,
  userNameParams,
  token,
}) => {
  return (
    <div>
      <Row gutter={[40, 40]}>
        <LoadMoreComponent
          {...{
            isMyPortfolio: true,
            payload: {
              orderBy: "DESC",
            },
            getWebsiteData: (params, payloadData) =>
              userGetPortfolioDetail(params, payloadData),
            data: portfolioWebsitesData,
            userNameParams,
            token,
          }}
        />
      </Row>
    </div>
  );
};

export default PortfolioWorkView;

import { PORTFOLIO_TAB, USER_PORTFOLIO_FILTER } from "@/src/const/nonPrimitive";
import dynamic from "next/dynamic";
import React from "react";
import PortfolioWorkView from "../portfolioWork/PortfolioWorkView";
const DropDownComponent = dynamic(
  () => import("@/src/components/CommonComponents/DropDown/DropDownComponent")
);

const ProfileTabMenuView = (props) => {
  const {
    portfolioWebsitesData,
    userNameParams,
    token,
    children,
    portfolioName,
    handleChangePortfolioName,
    handleFilterPortfolioWebsiteData,
  } = props;
  return (
    <React.Fragment>
      <div className="profile-tab-menu">
        <div className="profile-tab-wrap">
          {PORTFOLIO_TAB?.map((ele) => {
            return (
              <div
                key={ele}
                onClick={() => handleChangePortfolioName(ele)}
                className={`${portfolioName?.toLowerCase() === ele?.toLowerCase() ? "portfolio-menu-active" : ""} profile-tab`}
              >
                {ele}
              </div>
            );
          })}
        </div>
        <DropDownComponent
          options={USER_PORTFOLIO_FILTER}
          name="orderBy"
          defaultValue={"ASC"}
          // value={orderByFilter}
          // allowClear={orderByFilter !== "ASC" && true}
          handleChange={handleFilterPortfolioWebsiteData}
          className="profile-tab"
        />
      </div>
      <div className="portfolio-card-main">
        {portfolioName?.toLowerCase() === "work" ? (
          <PortfolioWorkView
            {...{ portfolioWebsitesData, userNameParams, token }}
          />
        ) : (
          children
        )}
      </div>
    </React.Fragment>
  );
};

export default ProfileTabMenuView;

"use client";
import React, { useState } from "react";
import GetInTouchModelView from "./GetInTouchModelView";
import { useNav } from "@/src/components/CommonComponents/context/NavbarProvider";
import { Form } from "antd";
import { userPostPortfolioGetInTouch } from "@/src/redux/actions";
import { GET_IN_TOUCH_RADIO, VALIDATION } from "@/src/const/nonPrimitive";
import { isEmpty } from "@/src/utils";
import { useDispatch } from "react-redux";
import { addWebsiteValidation } from "@/src/utils/validation/validation";

const GetInTouchModelContainer = ({
  token,
  userPortfolioProfileData,
  userNameParams,
}) => {
  const [form] = Form.useForm();
  const [isGetInTouchOpen, setIsGetInTouchOpen] = useState(false);
  const [getInTouchModalError, setGetInTouchModalError] = useState({});
  const [isSendRequestLoading, setIsSendRequestLoading] = useState(false);
  const [freeEmailServiceError, setFreeEmailServiceError] = useState("");
  const { setIsAuthModal } = useNav();
  const dispatch = useDispatch();

  const handleOpenGetInTouchModal = () => {
    if (token) {
      setIsGetInTouchOpen(!isGetInTouchOpen);
      setGetInTouchModalError({});
      setFreeEmailServiceError("");
      form?.resetFields();
    } else {
      setIsAuthModal(true);
    }
  };

  const handleGetInTouchChange = (e) => {
    const { name, value } = e.target;
    form.setFieldsValue({
      [name]: value,
    });
    setGetInTouchModalError({
      ...getInTouchModalError,
      [name]: "",
    });
    form?.getFieldValue();
  };

  const handleGetInTouchFocus = (name, maxLength) => {
    const { errors } = addWebsiteValidation(
      name,
      form?.getFieldValue(),
      getInTouchModalError,
      true
    );
    if (
      form?.getFieldValue(name)?.length === maxLength &&
      getInTouchModalError[name] !== VALIDATION[name]?.validMsg
    ) {
      errors[name] = "";
    }
    setGetInTouchModalError(errors);
  };

  const handleSendRequest = async () => {
    const values = form?.getFieldValue();
    const errorsToUpdate = {};
    GET_IN_TOUCH_RADIO?.forEach((ele) => {
      const name = ele;
      if (isEmpty(values[name])) {
        errorsToUpdate[name] = VALIDATION[name]?.requiredMsg;
      } else {
        errorsToUpdate[name] = "";
      }
    });
    setGetInTouchModalError({
      ...getInTouchModalError,
      ...errorsToUpdate,
      budget: errorsToUpdate?.budget,
      timeRequired: errorsToUpdate?.timeRequired,
    });
    if (
      Object.values(values).every((value) => value) &&
      Object.values(getInTouchModalError).every((value) => !value) &&
      Object.values(errorsToUpdate).every((value) => !value)
    ) {
      const payload = values;
      setIsSendRequestLoading(true);
      const res = await dispatch(
        userPostPortfolioGetInTouch(userNameParams, payload)
      );
      if (res?.status === 200) {
        setIsGetInTouchOpen(false);
        setGetInTouchModalError({});
        form?.resetFields();
      } else {
        if (res?.status === 429) {
          setFreeEmailServiceError(res?.data?.message);
        } else {
          setFreeEmailServiceError("");
        }
      }
      setIsSendRequestLoading(false);
    }
  };

  return (
    <GetInTouchModelView
      {...{
        isSendRequestLoading,
        form,
        freeEmailServiceError,
        userPortfolioProfileData,
        isGetInTouchOpen,
        getInTouchModalError,
        handleOpenGetInTouchModal,
        handleGetInTouchFocus,
        handleSendRequest,
        handleGetInTouchChange,
      }}
    />
  );
};

export default GetInTouchModelContainer;
